<template>
  <div class="records">
    <div
      class="simpleInfoPanel"
      v-if="hasPermission"
    >
      <ul class="filtroPanel">
        <li>
          <span>人员：</span>
          <el-input
            placeholder="输入姓名/用户名查询"
            v-model="params.name"
          ></el-input>
        </li>
        <li>
          <span>类型：</span>
          <el-select
            clearable
            popper-class="w-block-select-down"
            v-model="params.type"
            @change="changeType"
            placeholder="请选择记录类型"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <span>权益：</span>
          <el-select
            clearable
            v-model="params.powerType"
            placeholder="请选择权益项"
            popper-class="w-block-select-down"
          >
            <el-option
              v-for="item in rightsOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li>
          <el-button
            class="btnPrimary btnCommon"
            @click="toSearch"
          >查询</el-button>
        </li>
      </ul>
    </div>
    <div
      class="simpleInfoPanel mt15"
      v-if="hasPermission"
    >
      <el-table
        :data="tableData"
        class="tbsWrap"
      >
        <el-table-column
          prop="name"
          width="105"
          label="人员姓名"
        >
        </el-table-column>
        <el-table-column
          prop="project"
          label="绑定项目"
        >
        </el-table-column>
        <el-table-column
          prop="division"
          width="105"
          label="所属部门"
        >
        </el-table-column>
        <el-table-column
          prop="role"
          width="105"
          label="角色"
        >
        </el-table-column>
        <el-table-column
          prop="recordsType"
          width="105"
          label="记录类型"
        >
        </el-table-column>
        <el-table-column
          prop="content"
          label="权益操作内容"
        >
          <template slot-scope="scope">
            {{scope.row.recordsType+'“'+scope.row.content+'” '+scope.row.num+' 个'}}
          </template>
        </el-table-column>
        <el-table-column
          prop="handler"
          label="操作人"
        >
        </el-table-column>
        <el-table-column
          prop="handleDate"
          label="操作时间"
        >
        </el-table-column>
      </el-table>
      <div class="w-page-sty">
        <el-pagination
          background
          layout="total, prev, pager, next"
          :total="params.total"
          :page-size="params.pageSize"
          :current-page="params.pageNum"
          @current-change="handlePages"
        >
        </el-pagination>
      </div>
    </div>
    <div class="simpleInfoPanel">
      <div
        class="jurisdiction-wrap"
        v-if="!hasPermission"
      >
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'
export default {
  name: 'records',
  components: {},
  data () {
    return {
      form: {
        member: '',
        type: '',
        rights: ''
      },
      tableData: [],
      rightsOptions: [
        {
          value: 1,
          label: '子账号配置'
        },
        {
          value: 2,
          label: '查看品牌权益'
        },
        {
          value: 3,
          label: '品牌联系特权'
        },
        {
          value: 5,
          label: '查看项目权益'
        },
        {
          value: 7,
          label: '项目对标权益数'
        }
      ],
      typeOptions: [
        {
          value: 0,
          label: '分配'
        },
        {
          value: 1,
          label: '回收'
        }
      ],
      params: {
        deploymentId: '', // 部门id ,
        isAdmin: '', // 是否主账号(不用传) ,
        name: '', // 人员姓名或用户名 ,
        total: 0,
        pageNum: 1, // 页码 ,
        pageSize: 10, // 每页条数 ,
        powerType: '', // 权益类型:1 子账号配置（子账号开通）;2 查看品牌权益;3 品牌联系特权;(4 品牌导出已删除);4 查看项目权益;(6 品牌智能匹配已删除) ,
        projectId: '', // 项目ID ,
        roleId: '', // 角色id ,
        state: '', // 分配状态:0 未分配;1 已分配 ,
        type: '', // 操作状态:0 分配;1 回收 ,
        userId: '' // 登录用户ID(不用传)
      },
      hasPermission: true,
      pageNum: 1
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    user (newVal, oldVal) {
      // const permissionArr = [94]
      // const userAuthorities = this.user.confIds.filter(item => {
      //   return permissionArr.includes(item.id)
      // })
      // if (userAuthorities.length) {
      //   this.getAuthorities(userAuthorities)
      // }
    }
  },
  mounted () {
    this.userAuthorities = this.user.confIds.filter(item => {
      return item.id === 94
    })
    if (this.userAuthorities.length) {
      this.fectchList()
      this.hasPermission = true
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    getAuthorities (val) {
      console.log('val', val)
      if (val && val.length) {
        this.hasPermission = Boolean(val[0].func)
      } else {
        this.hasPermission = false
      }
      if (this.hasPermission) {
        this.fectchList()
      }
    },
    changeType (val) {
    },
    handlePages (val) {
      this.params.pageNum = val
      this.fectchList()
    },
    toSearch () {
      this.params.pageNum = 1
      this.fectchList()
    },
    fectchList () {
      const recordsArr = ['子账号配置', '查看品牌权益', '品牌联系特权', '', '查看项目权益', '', '项目对标权益']
      this.axios.post(api.getEditionRecords, this.params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.tableData = resData.dataList.map(item => {
            const tempObj = {
              id: item.userId,
              name: item.targetName || '--',
              project: item.projectNames || '--',
              division: item.deploymentNames || '--',
              role: item.roleName || '--',
              recordsType: item.type ? '回收' : '分配',
              num: item.num,
              content: item.powerType ? recordsArr[item.powerType - 1] : '--',
              handler: item.userName || '--',
              handleDate: item.createTime || '--'
            }
            return tempObj
          })
          this.params.total = resData.total
          console.log(resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    onSearch () {
    },
    handleAllot () { },
    handleReversion () { }
  }
}
</script>

<style scoped lang="stylus"></style>
